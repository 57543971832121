<template>
  <vue-advanced-chat
    height="calc(100vh - 20px)"
    .current-user-id="currentUserId"
    .rooms="JSON.stringify(rooms)"
    .rooms-loaded="true"
    .messages="JSON.stringify(messages)"
    .messages-loaded="messagesLoaded"
    @send-message="sendMessage($event.detail[0])"
    @fetch-messages="fetchMessages($event.detail[0])"
  />
</template>

<script>
import { defineComponent, ref } from "vue";
import { register } from "vue-advanced-chat";

export default defineComponent({
  components: {},
  setup() {
    register();
    const messages = ref([]);
    const currentUserId = ref("1234");
    const messagesLoaded = ref(false);

    const sendMessage = (message) => {
      console.log("sending message", message);
      messages.value = [
        ...messages.value,
        {
          _id: messages.value.length,
          content: message.content,
          senderId: currentUserId,
          timestamp: new Date().toString().substring(16, 21),
          date: new Date().toDateString(),
        },
      ];
    };

    const fetchMessages = ({ options = {} }) => {
      setTimeout(() => {
        if (options.reset) {
          messages.value = addMessages(true);
        } else {
          messages.value = [...addMessages(), ...messages.value];
          messagesLoaded.value = true;
        }
        // this.addNewMessage()
      });
    };

    const addMessages = (reset) => {
      console.log("adding message", reset);
      const messagesTmp = [];
      for (let i = 0; i < 30; i++) {
        messagesTmp.push({
          _id: reset ? i : messages.value.length + i,
          content: `${reset ? "" : "paginated"} message ${i + 1}`,
          senderId: "4321",
          username: "John Doe",
          date: "13 November",
          timestamp: "10:20",
        });
      }
      return messagesTmp;
    };

    const addNewMessage = () => {
      setTimeout(() => {
        this.messages = [
          ...this.messages,
          {
            _id: this.messages.length,
            content: "NEW MESSAGE",
            senderId: "1234",
            timestamp: new Date().toString().substring(16, 21),
            date: new Date().toDateString(),
          },
        ];
      }, 2000);
    };
    return {
      currentUserId,
      rooms: [
        {
          roomId: "1",
          roomName: "Room 1",
          avatar: "https://66.media.tumblr.com/avatar_c6a8eae4303e_512.pnj",
          users: [
            { _id: "1234", username: "John Doe" },
            { _id: "4321", username: "John Snow" },
          ],
        },
      ],
      messages,
      messagesLoaded,
      fetchMessages,
      addMessages,
      sendMessage,
      addNewMessage,
    };
  },
});
</script>
